<template>
  <v-container class="pt-0 mt-5 text-center flex-grow-1">
    <background-video :settings="backgroundVideo"></background-video>
    <div class="to-front px-5">
      <v-row class="fill-height" align="center" justify="start">
        <template v-for="(work, i) in works">
          <v-col :key="i" v-if="shouldDisplay(work)" cols="12" sm="6" md="3" class="col-item">
            <v-hover v-slot="{ hover }">
              <v-card :class="{ 'on-hover': hover, 'no-video': !work.youtubeId && !work.vimeoId ? true : false }" @click="selectWork(work)">
                <v-img cover :src="work.posterSm" class="blur-background" style="width: 100%; height: 100%;"></v-img>
                <v-img contain :src="work.posterSm" height="300px" class="d-flex align-end"> </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </div>
    <v-overlay :value="overlay" @click="overlay = !overlay">
      <works-overlay :work="selectedWork" @close="overlay = false" />
    </v-overlay>
  </v-container>
</template>

<script>
import { gsap, Power2 } from "gsap";
import { defaultBack } from "../helpers/video-background-paths";

export default {
  components: {
    "works-overlay": require("@/components/works/WorksOverlay.vue").default,
    "background-video": require("@/components/shared/BackgroundVideo.vue").default,
  },
  beforeCreate() {
    this.$store.dispatch("getWork");
  },
  mounted() {
    let speed = 0.1;
    document.querySelector("html").style.overflowY = "visible";

    const animate = (vcards) => {
      [...vcards].forEach((vcard, index) => {
        gsap.fromTo(vcard, { width: 0, opacity: 0 }, { width: "auto", opacity: 1, duration: speed, delay: (index + 1) * speed, ease: Power2.easeOut });
      });
    };

    const wait = setInterval(() => {
      let vcards = document.querySelectorAll(".v-card");
      if (vcards && vcards.length) {
        clearInterval(wait);
        animate(vcards);
      }
    }, 50);
  },
  watch: {
    overlay: function(isOpen) {
      document.querySelector("html").style.overflowY = isOpen ? "hidden" : null;
    },
  },
  data() {
    return {
      overlay: false,
      selectedWork: null,
      backgroundVideo: {
        sources: [defaultBack.video],
        img: defaultBack.image,
        opacity: 1,
      },
    };
  },
  methods: {
    selectWork(work) {
      if (!work.youtubeId && !work.vimeoId) return;
      this.overlay = true;
      this.selectedWork = work;
    },
    shouldDisplay(work) {
      if (!work.posterLg || !work.posterSm || work.hide) return false;
      return true;
    },
  },
  computed: {
    works: {
      get() {
        return this.$store.getters.workByReleaseDate;
      },
    },
  },
};
</script>

<style lang="scss">
/* if backdrop support: very transparent and blurred */
@supports ((-webkit-filter: blur(10px)) or (filter: blur(10px))) {
  .blur-background {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    .v-image__image {
      transform: scale(1);
      transition-property: transform;
      transition-duration: 0.5s;
      transition-timing-function: ease-out;
    }
  }
}

.col-item {
  transition-property: max-width, max-height !important;
  transition-duration: 0.35s, 0.35s !important;
}

.v-card {
  transition-property: box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;

  &.no-video {
    cursor: default;
    .v-ripple__container {
      display: none !important;
    }
  }

  &.on-hover {
    box-shadow: 0px 0px 6px 5px rgba(255, 255, 255, 0.8) !important;

    .blur-background {
      .v-image__image {
        transform: scale(1.75) !important;
      }
    }
  }

  .blur-background {
    position: absolute;
    opacity: 1;

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
