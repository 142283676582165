<template>
  <div class="d-flex justify-center align-center works-overlay">
    <div class="click-catcher" @click="$emit('close')">
      <v-icon large color="white" style="position: absolute; top: 1rem; right: 1rem; cursor: pointer;">mdi-close-circle</v-icon>
    </div>
    <div class="text-center to-front" height="100%">
      <v-row class="d-flex align-center" style="width:90vw; max-width: 1920px;">
        <v-col cols="12" md="4" lg="4" class="d-none d-md-flex">
          <v-img contain :elevation="50" :src="work.posterLg" :lazy-src="work.posterSm" max-height="70vh"></v-img>
        </v-col>
        <v-col cols="12" md="8" lg="8">
          <v-card :elevation="50" max-width="100%">
            <v-responsive :aspect-ratio="16 / 9">
              <iframe :aspect-ratio="16 / 9" class="youtube-embedded" :src="getSrc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["work"],
  mounted() {},
  computed: {
    getSrc() {
      if (this.work.youtubeId) {
        return `https://www.youtube.com/embed/${this.work.youtubeId}`;
      } else if (this.work.vimeoId) {
        return `https://player.vimeo.com/video/${this.work.vimeoId}`;
      }
    },
  },
};
</script>

<style lang="scss">
.youtube-embedded {
  width: 100%;
  height: 100%;
}

.v-overlay__content {
  width: 100%;
  height: 100%;
}

.works-overlay {
  height: calc(100vh - 64px);
  margin-top: 64px;
  position: relative;
}

/* .click-catcher {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
} */

.click-catcher {
  position: absolute;
  background-color: rgba(33, 33, 33, 0.46);
  opacity: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .click-catcher {
    background-color: transparent;
    opacity: 1;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}
</style>
